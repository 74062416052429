@tailwind base;
@tailwind components;
@tailwind utilities;





* {
    margin: 0;
    box-sizing: border-box;
  }
  
  body {
    /* font-family: "Outfit", sans-serif; */
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
  }
  
  button,
  input,
  textarea,
  select {
    font: inherit;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  LazyLoadImage,
  video,
  canvas,
  svg {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    border: none;
    outline: none;
  }
  
  .container {
    max-width: 1550px;
    margin: 0 auto;
  }
  
  